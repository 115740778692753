@use "../../react-components/styles/theme.scss";
@import 'shared';

.loader-wrap {
  pointer-events: none;
  position: relative;
  width: 100px;
  height: 90px;
}

.loader-bottom {
  position: fixed;
  bottom: 8px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  left: 50%;
}

.loader-mid {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  left: 50%;
}

.loading-panel {
  @extend %default-font;
  position: absolute;
  top: 0;
  left: 0;
  background: theme.$loading-screen-background;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loading-panel__logo {
  width: 247px;
  margin: 20px 0 10px 0;
  filter: drop-shadow(0 0 4px #888);
}

.loading-panel__powered-by {
  position: absolute;
  bottom: 12px;
  left: 12px;
  font-weight: normal;
  font-size: 0.8em;
  color: theme.$text1-color;

  @media (max-width: 768px), (max-height: 480px) {
    display: none;
  }

  a {
    color: theme.$text1-color;
  }
}

.loading-panel__powered-by__prefix {
  margin-right: 4px;
}

.loader-center,
.loader:before,
.loader:after {
  background: theme.$active-color;
  -webkit-animation: loader-animation 1s infinite ease-in-out;
  animation: loader-animation 1s infinite ease-in-out;
  width: 0.6em;
  height: 1em;
  border-radius: 5px;
}
.loader {
  color: theme.$active-color;
  text-indent: -9999em;
  margin-left: 4em;
  margin-top: 4em;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader-center,
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader-center {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before {
  left: -1.2em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.2em;
}
@-webkit-keyframes loader-animation {
  0%,
  80%,
  100% {
    height: 2em;
    top: 0;
  }
  40% {
    height: 3.5em;
    top: -0.75em;
  }
}
@keyframes loader-animation {
  0%,
  80%,
  100% {
    height: 2em;
    top: 0;
  }
  40% {
    height: 3.5em;
    top: -0.75em;
  }
}

:local(.loading-text) {
  font-weight: normal;
  font-size: 0.8em;
  color: theme.$text1-color;
  margin-top: 0;
}
